import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api, {
  agreementsUrl,
  rolesUrl,
  usersUrl,
  vouchersUrl,
} from '../../constants/api';
import { createUser, updateUser } from '../../store/session/usersSlice';
import CreatableSelect from 'react-select/creatable';

const UserDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [roles, setRoles] = useState([]);
  const [agreements, setAgreements] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();

  const createNewUser = async () => {
    await dispatch(createUser(userDetails));
    navigate('/users');
  };

  const editCurrentUser = async () => {
    await dispatch(updateUser(userDetails));
    navigate('/users');
  };

  useEffect(() => {
    getRoles();
    getVouchers();
    getAgreements();

    return () => {};
  }, []);

  useEffect(() => {
    getUserDetails();
    return () => {};
  }, [id]);

  const getUserDetails = async () => {
    const { data } = await api.get(`${usersUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setUserDetails(data.data);
  };

  const getRoles = async () => {
    const { data } = await api.get(rolesUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setRoles(data.data);
  };

  const getVouchers = async () => {
    const { data } = await api.get(vouchersUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setVouchers(data.data);
  };

  const getAgreements = async () => {
    const { data } = await api.get(agreementsUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setAgreements(data.data);
  };

  const updateUserDetails = (val) => {
    setUserDetails({ ...userDetails, [val.target.name]: val.target.value });
  };

  return (
    <div className='container mx-auto mt-32 text-left flex flex-col gap-4'>
      <h2 className='font-bold text-xl'>
        {id == 'new' ? 'Create New' : 'Edit'} User
      </h2>
      <hr className='my-4' />
      <div className='flex flex-col'>
        <label htmlFor='firstname'>First Name</label>
        <input
          className='p-2 rounded-md border'
          id='firstname'
          placeholder='John'
          name='firstname'
          onChange={updateUserDetails}
          value={userDetails.firstname}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='surname'>Surname</label>
        <input
          className='p-2 rounded-md border'
          id='surname'
          placeholder='Doe'
          name='surname'
          onChange={updateUserDetails}
          value={userDetails.surname}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='email'>Email</label>
        <input
          className='p-2 rounded-md border'
          id='email'
          placeholder='john@example.com'
          type={'email'}
          name='email'
          onChange={updateUserDetails}
          value={userDetails.email}
        />
      </div>
      <div className='flex flex-col'>
        <label htmlFor='role'>Role</label>
        <select
          className='p-2 rounded-md border'
          id='role'
          placeholder='Select a Role'
          onChange={(e) =>
            setUserDetails({ ...userDetails, roleId: e.target.value })
          }
          value={userDetails.roleId}
        >
          <option value={-1}>Select a Role</option>
          {roles.map((role) => (
            <option value={role.role_id}>{role.roleName}</option>
          ))}
        </select>
      </div>
      {userDetails.roleId &&
        roles.length > 0 &&
        userDetails.roleId != -1 &&
        roles
          .filter((r) => r.role_id == userDetails.roleId)[0]
          .roleName.toLowerCase()
          .includes('user') && (
          <div className={`flex flex-col gap-4`}>
            <div className='flex flex-col'>
              <div className='flex gap-2 items-center'>
                <input
                  onChange={(val) =>
                    setUserDetails({
                      ...userDetails,
                      [val.target.name]: val.target.checked,
                    })
                  }
                  checked={userDetails.voucherAccess}
                  name='voucherAccess'
                  id='voucherAccess'
                  type='checkbox'
                />
                <label className='select-none' htmlFor='voucherAccess'>
                  Voucher Access
                </label>
              </div>
              {userDetails.voucherAccess && (
                <CreatableSelect
                  onChange={(val) => {
                    setUserDetails({
                      ...userDetails,
                      vouchers: val.map((v) => {
                        return { voucherName: v.value };
                      }),
                    });
                  }}
                  isMulti
                  isClearable
                  isSearchable
                  value={userDetails.vouchers?.map((voucher) => {
                    return {
                      label: voucher.voucherName,
                      value: voucher.voucherName,
                    };
                  })}
                  options={vouchers.map((voucher) => {
                    return {
                      label: voucher.voucherName,
                      value: voucher.voucherName,
                    };
                  })}
                />
              )}
            </div>
            <div className='flex flex-col'>
              <div className='flex gap-2 items-center'>
                <input
                  onChange={(val) =>
                    setUserDetails({
                      ...userDetails,
                      [val.target.name]: val.target.checked,
                    })
                  }
                  checked={userDetails.agreementAccess}
                  name='agreementAccess'
                  id='agreementAccess'
                  type='checkbox'
                />
                <label className='select-none' htmlFor='agreementAccess'>
                  Agreement Access
                </label>
              </div>
              {userDetails.agreementAccess && (
                <CreatableSelect
                  onChange={(val) => {
                    setUserDetails({
                      ...userDetails,
                      agreements: val.map((v) => {
                        return { agreementName: v.value };
                      }),
                    });
                  }}
                  isMulti
                  isClearable
                  isSearchable
                  value={userDetails.agreements?.map((agreement) => {
                    return {
                      label: agreement.agreementName,
                      value: agreement.agreementName,
                    };
                  })}
                  options={agreements.map((agreement) => {
                    return {
                      label: agreement.agreementName,
                      value: agreement.agreementName,
                    };
                  })}
                />
              )}
            </div>
          </div>
        )}
      <div className='flex gap-4 mt-4'>
        <button
          onClick={() => {
            if (id === 'new') {
              createNewUser();
            } else {
              editCurrentUser();
            }
          }}
          className='bg-primary px-6 py-2 font-bold text-white rounded-md min-w-[200px]'
        >
          Save
        </button>
        <Link
          to={'/users'}
          className=' px-6 py-2 font-bold text-primary rounded-md min-w-[200px]'
        >
          Cancel
        </Link>
      </div>
    </div>
  );
};

export default UserDetail;
