import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './screens/Home/Home';
import Login from './screens/Login/Login';
import { store } from './store';
import { authenticate } from './store/session/authSlice';
import UserDetail from './screens/Users/UserDetail';
import Users from './screens/Users/Users';
import CurrentCourses from './screens/CurrentCourses/CurrentCourses';
import UpcomingCourses from './screens/UpcomingCourses/UpcomingCourses';
import PastResults from './screens/PastResults/PastResults';
import Header from './components/Header/Header';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import ResetPassword from './screens/ResetPassword/ResetPassword';

function RequireAuth({ children, requiredRole }) {
  const auth = store.getState().auth;
  const location = useLocation();

  if (!auth.authenticated) {
    store.dispatch(authenticate());
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (requiredRole) {
    if (auth.me.role.roleName !== requiredRole) {
      return <Navigate to='/' replace />;
    }
  }

  return children;
}

function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/resetpassword/:token' element={<ResetPassword />} />
        <Route path='/activateaccount/:token' element={<ResetPassword />} />
        <Route path='/' element={<Navigate to='/Voucher' replace />} />
        <Route
          path='/Voucher'
          element={
            <RequireAuth>
              <>
                <Header />
                <Home />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Agreement'
          element={
            <RequireAuth>
              <>
                <Header />
                <Home />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Agreement/current'
          element={
            <RequireAuth>
              <>
                <Header />
                <CurrentCourses />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Agreement/upcoming'
          element={
            <RequireAuth>
              <>
                <Header />
                <UpcomingCourses />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Agreement/past'
          element={
            <RequireAuth>
              <>
                <Header />
                <PastResults />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Voucher/current'
          element={
            <RequireAuth>
              <>
                <Header />
                <CurrentCourses />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Voucher/upcoming'
          element={
            <RequireAuth>
              <>
                <Header />
                <UpcomingCourses />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/Voucher/past'
          element={
            <RequireAuth>
              <>
                <Header />
                <PastResults />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/users'
          element={
            <RequireAuth requiredRole='Site Admin'>
              <>
                <Header showTabs={false} />
                <Users />
              </>
            </RequireAuth>
          }
        />
        <Route
          path='/users/:id'
          element={
            <RequireAuth requiredRole='Site Admin'>
              <>
                <Header showTabs={false} />
                <UserDetail />
              </>
            </RequireAuth>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
