import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUsers } from '../../store/session/usersSlice';
import { Link } from 'react-router-dom';

const Users = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { users, count, total } = useSelector((state) => state.users);
  const [userToDelete, setUserToDelete] = useState();

  useEffect(() => {
    dispatch(getUsers({ page }));
    return () => {};
  }, [page]);

  const deleteUserRequest = (id) => {
    setUserToDelete(users.filter((user) => user.id === id)[0]);
  };

  const deleteSelectedUser = async () => {
    await dispatch(deleteUser(userToDelete.id));
    dispatch(getUsers({ page }));
    setUserToDelete(null);
  };

  return (
    <>
      {userToDelete && (
        <div className='left-0 right-0 top-0 bottom-0 absolute bg-opacity-30 bg-black flex justify-center items-center'>
          <div className='bg-white p-4 rounded-md flex flex-col gap-4 border items-center'>
            <p className='font-bold text-lg max-w-xs'>
              Are you sure you want to delete user {userToDelete?.firstname}{' '}
              {userToDelete?.surname}?
            </p>
            <p>This action is permanent</p>
            <div className='flex justify-between gap-4'>
              <button
                onClick={deleteSelectedUser}
                className='bg-red-500 px-6 py-2 font-bold text-white rounded-md min-w-[200px]'
              >
                Delete
              </button>
              <button
                onClick={() => setUserToDelete(null)}
                className='bg-gray-200 px-6 py-2 font-bold text-primary rounded-md min-w-[200px]'
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className='container mx-auto mt-32'>
        <Table
          title='Users'
          headers={[
            { key: 'firstname', label: 'First Name' },
            { key: 'surname', label: 'Surname' },
            { key: 'email', label: 'Email' },
            { key: 'role', label: 'Role' },
            { key: 'voucherAccess', label: 'Voucher Access' },
            { key: 'agreementAccess', label: 'Agreement Access' },
            { key: '', label: '' },
          ]}
          entries={users?.map((user) => {
            return {
              ...user,
              role: user.role.roleName,
              canDelete: user.role.roleName !== 'Site Admin',
              voucherAccess: user.voucherAccess ? 'Yes' : 'No',
              agreementAccess: user.agreementAccess ? 'Yes' : 'No',
            };
          })}
          count={users.length}
          total={count}
          page={page}
          limit={25}
          linkTo={
            <Link className='text-primary font-bold' to='/users/new'>
              Create New User
            </Link>
          }
          onDelete={deleteUserRequest}
          nextPage={() => {
            setPage(page + 1);
          }}
          previousPage={() => {
            setPage(page - 1);
          }}
          setPage={(index) => {
            setPage(index);
          }}
        />
      </div>
    </>
  );
};

export default Users;
