import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Pagination from '../Pagination/Pagination';

const Table = ({
  title,
  headers,
  entries = [],
  count,
  total,
  page,
  limit,
  linkTo,
  showActions = true,
  showPagination = true,
  loading,
  onDelete,
  colorColumns = false,
  nextPage,
  previousPage,
  setPage,
}) => {
  return (
    <div className='flex flex-col justify-center h-full'>
      <div className='w-full mx-auto bg-white rounded-md border border-gray-200'>
        <header className='px-5 py-4 border-b border-gray-100 flex justify-between'>
          <div className='flex flex-col items-start'>
            <h2 className='font-semibold text-gray-800'>{title}</h2>
          </div>
          <div className='flex items-center gap-4'>{linkTo}</div>
        </header>
        <div className='p-3'>
          <div className='overflow-x-auto'>
            <table className='table-auto w-full'>
              <thead className='text-xs font-semibold uppercase text-gray-400 bg-gray-50'>
                <tr>
                  {headers.map((header) => (
                    <th className='p-2 whitespace-nowrap cursor-pointer hover:bg-gray-200'>
                      <div
                        className='font-semibold text-left'
                        //   onClick={() => dispatch(updateSort('firstname'))}
                      >
                        {header.label}
                        {/* {sort == 'firstname' && <>&#9660;</>}{' '} */}
                        {/* {sort == '-firstname' && <>&#9650;</>} */}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='text-sm divide-y divide-gray-100'>
                {entries.map((entry) => (
                  <tr>
                    {headers
                      .filter((h) => h.key !== '')
                      .map((header) => (
                        <td
                          className={`p-2 whitespace-nowrap text-left ${
                            (colorColumns &&
                              header.key.toLowerCase() === 'pass mark') ||
                            header.key.toLowerCase() === 'grade' ||
                            header.key.toLowerCase() === 'benchmark' ||
                            header.key.toLowerCase() === 'enrolled' ||
                            header.key.toLowerCase() === 'login days'
                              ? entry.color
                              : ''
                          }`}
                        >
                          {entry[header.key] ?? 'N/A'}
                        </td>
                      ))}
                    {showActions && (
                      <td className='p-2 whitespace-nowrap text-left gap-4 flex'>
                        <Link
                          to={`/users/${entry.id}`}
                          className='text-blue-500'
                        >
                          Edit
                        </Link>
                        {entry.canDelete && (
                          <button
                            onClick={() => onDelete(entry.id)}
                            className='text-red-500'
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {entries.length === 0 && !loading && (
              <p className='text-gray-400 pt-4'>No Results</p>
            )}
            {loading && <p className='text-gray-400 pt-4'>Loading...</p>}
            {showPagination && (
              <Pagination
                nextPage={() => {
                  if (page != Math.ceil(total / limit)) {
                    nextPage();
                  }
                }}
                previousPage={() => {
                  if (page != 1) {
                    previousPage();
                  }
                }}
                selectedPage={setPage}
                total={total}
                count={count}
                currentPage={page}
                limit={limit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
