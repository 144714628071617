import React, { useRef, useEffect } from 'react';

const FunnelChart = ({ values }) => {
  const canvasRef = useRef(null);

  const draw = (ctx, canvas) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#367FFF';
    ctx.beginPath();

    ctx.textAlign = 'center';
    ctx.font = 'bold 10px Arial';
    ctx.fillStyle = '#000';

    const enrolledWidth =
      (canvas.width - canvas.width * (values.enrolled / values.total)) / 2;

    const activeWidth =
      (canvas.width - canvas.width * (values.active / values.total)) / 2;

    const passWidth =
      (canvas.width - canvas.width * (values.pass / values.total)) / 2;

    ctx.fillText(`${values.total} Total Students`, canvas.width / 2, 10);
    //Total
    ctx.moveTo(0, 15);
    ctx.lineTo(canvas.width, 15);

    // Enrolled
    ctx.lineTo(canvas.width - enrolledWidth, canvas.height * (1 / 3));
    ctx.lineTo(enrolledWidth, canvas.height * (1 / 3));
    ctx.fillText(
      `${values.enrolled} Enrolled`,
      canvas.width / 2,
      10 + canvas.height * (1 / 3)
    );
    ctx.moveTo(enrolledWidth, 15 + canvas.height * (1 / 3));
    ctx.lineTo(canvas.width - enrolledWidth, 15 + canvas.height * (1 / 3));

    //Active
    ctx.lineTo(canvas.width - activeWidth, canvas.height * (2 / 3));
    ctx.lineTo(activeWidth, canvas.height * (2 / 3));
    ctx.fillText(
      `${values.active} Active`,
      canvas.width / 2,
      10 + canvas.height * (2 / 3)
    );

    ctx.moveTo(activeWidth, 15 + canvas.height * (2 / 3));
    ctx.lineTo(canvas.width - activeWidth, 15 + canvas.height * (2 / 3));

    ctx.lineTo(canvas.width - passWidth, canvas.height - 15);
    ctx.lineTo(passWidth, canvas.height - 15);
    ctx.fillText(`${values.pass} to Pass`, canvas.width / 2, canvas.height);

    ctx.fillStyle = '#1C74AC';
    ctx.fill();
  };

  useEffect(() => {
    if (!values) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    //Our draw come here
    draw(context, canvas);
  }, [values]);

  return <canvas width={300} height={400} ref={canvasRef} />;
};

export default FunnelChart;
