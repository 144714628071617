import React from 'react';

const Input = ({ placeholder, onChange, type, value }) => {
  return (
    <input
      onChange={onChange}
      placeholder={placeholder}
      className='px-6 py-3 rounded-md border-2 border-gray-200 min-w-[200px]'
      type={type ?? 'text'}
      value={value}
    />
  );
};

export default Input;
