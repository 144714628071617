import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api, {
  currentEnrolledCoursesUrl,
  funnelUrl,
  institutionDetailsUrl,
  institutionNamesUrl,
  institutionsUrl,
  pastEnrolledCoursesUrl,
  upcomingEnrolledCoursesUrl,
  usersUrl,
} from '../../constants/api';

const initialState = {
  selectedInstitution: null,
  datatype: 'Voucher',
  status: 'idle',
  institutions: [],
  currentEnrolledstatus: 'idle',
  currentEnrolled: [],
  upcomingEnrolledstatus: 'idle',
  upcomingEnrolled: [],
  pastEnrolledStatus: 'idle',
  pastEnrolled: [],
  institutionDetailsStatus: 'idle',
  institutionDetails: {},
  funnelStatus: 'idle',
  funnelValues: null,
  identifiers: ['all'],
  agreements: [],
  institutionNames: 'All',
};

export const getInstitutionDetails = createAsyncThunk(
  'institution/details',
  async (_, { getState, requestId }) => {
    const dataType = getState().institution.dataType;
    const identifiers = getState().institution.identifiers;
    const agreements = getState().institution.agreements;
    const me = getState().auth.me;
    console.log(agreements);
    const response = await api.get(institutionDetailsUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: sanitizeIdentifiers(dataType, identifiers, me, agreements),
    });
    return response.data;
  }
);

export const getInstitutions = createAsyncThunk(
  'institution/get',
  async (agreements, { getState, requestId }) => {
    let params = {};
    if (agreements) {
      params.agreements = agreements.join(',');
    }
    const response = await api.get(institutionsUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params,
    });
    return response.data;
  }
);

export const getEnrolledInCurrent = createAsyncThunk(
  'institution/enrolledCurrent',
  async (_, { getState, requestId }) => {
    const dataType = getState().institution.dataType;
    const identifiers = getState().institution.identifiers;
    const agreements = getState().institution.agreements;
    const me = getState().auth.me;

    const response = await api.get(currentEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: sanitizeIdentifiers(dataType, identifiers, me, agreements),
    });
    return response.data;
  }
);

export const getInstitutionNames = createAsyncThunk(
  'institution/institutionNames',
  async (_, { getState, requestId }) => {
    const me = getState().auth.me;
    if (me.role.roleName.toLowerCase().includes('user')) {
      const response = await api.get(institutionNamesUrl, {
        headers: {
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          identifiers: me.agreements.map((m) => m.agreementName).join(','),
        },
      });
      return response.data.data.map((d) => d.institution).join(',');
    }
    return 'All';
  }
);

export const getEnrolledInUpcoming = createAsyncThunk(
  'institution/enrolledUpcoming',
  async (_, { getState, requestId }) => {
    const dataType = getState().institution.dataType;
    const identifiers = getState().institution.identifiers;
    const agreements = getState().institution.agreements;
    const me = getState().auth.me;
    const response = await api.get(upcomingEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: sanitizeIdentifiers(dataType, identifiers, me, agreements),
    });
    return response.data;
  }
);

export const getEnrolledInPast = createAsyncThunk(
  'institution/enrolledPast',
  async (_, { getState, requestId }) => {
    const dataType = getState().institution.dataType;
    const identifiers = getState().institution.identifiers;
    const agreements = getState().institution.agreements;
    const me = getState().auth.me;
    const response = await api.get(pastEnrolledCoursesUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: sanitizeIdentifiers(dataType, identifiers, me, agreements),
    });
    return response.data;
  }
);

export const getFunnelValues = createAsyncThunk(
  'institution/funnelValues',
  async (_, { getState, requestId }) => {
    const dataType = getState().institution.dataType;
    const identifiers = getState().institution.identifiers;
    const agreements = getState().institution.agreements;
    const me = getState().auth.me;
    const response = await api.get(funnelUrl, {
      headers: {
        Authorization: `Bearer ${getState().auth.token}`,
      },
      params: sanitizeIdentifiers(dataType, identifiers, me, agreements),
    });
    return response.data;
  }
);

export const setInstitution = createAsyncThunk(
  'institution/setInstitution',
  async (institution, { getState, requestId }) => {
    const response = await api.get(
      `${institutionsUrl}/${institution.id}/agreements`,
      {
        headers: {
          Authorization: `Bearer ${getState().auth.token}`,
        },
      }
    );
    return { data: response.data.data, institution };
  }
);

export const institutionSlice = createSlice({
  name: 'institution',
  initialState,
  reducers: {
    setDataType: (state, action) => {
      state.currentEnrolledstatus = 'idle';
      state.upcomingEnrolledstatus = 'idle';
      state.pastEnrolledStatus = 'idle';
      state.institutionDetailsStatus = 'idle';
      state.funnelStatus = 'idle';
      state.dataType = action.payload;
    },
    setIdentifiers: (state, action) => {
      state.currentEnrolledstatus = 'idle';
      state.upcomingEnrolledstatus = 'idle';
      state.pastEnrolledStatus = 'idle';
      state.institutionDetailsStatus = 'idle';
      state.funnelStatus = 'idle';
      state.identifiers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstitutions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInstitutions.fulfilled, (state, action) => {
        state.status = 'success';
        state.institutions = action.payload.data;
        state.selectedInstitution = action.payload.data[0];
      })
      .addCase(getInstitutions.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(getInstitutionDetails.fulfilled, (state, action) => {
        state.institutionDetailsStatus = 'success';
        state.institutionDetails = action.payload.data;
      })
      .addCase(getEnrolledInCurrent.fulfilled, (state, action) => {
        state.currentEnrolledstatus = 'success';
        state.currentEnrolled = action.payload.data;
      })
      .addCase(getEnrolledInUpcoming.fulfilled, (state, action) => {
        state.upcomingEnrolledstatus = 'success';
        state.upcomingEnrolled = action.payload.data;
      })
      .addCase(getEnrolledInPast.fulfilled, (state, action) => {
        state.pastEnrolledStatus = 'success';
        state.pastEnrolled = action.payload.data;
      })
      .addCase(getFunnelValues.fulfilled, (state, action) => {
        state.funnelStatus = 'success';
        state.funnelValues = action.payload.data;
      })
      .addCase(getInstitutionNames.fulfilled, (state, action) => {
        state.institutionNames = action.payload;
      })
      .addCase(setInstitution.fulfilled, (state, action) => {
        state.currentEnrolledstatus = 'idle';
        state.upcomingEnrolledstatus = 'idle';
        state.pastEnrolledStatus = 'idle';
        state.institutionDetailsStatus = 'idle';
        state.funnelStatus = 'idle';
        state.agreements = action.payload.data;
        state.selectedInstitution = action.payload.institution;
      });
  },
});

export const sanitizeIdentifiers = (dataType, identifiers, me, agreements) => {
  if (identifiers[0] === 'all') {
    if (agreements.length > 0 && dataType.toLowerCase() === 'agreement') {
      identifiers = agreements.map((m) => m.agreementName).join(',');
    } else {
      identifiers = me[dataType.toLowerCase() + 's']
        .map((m) => m[dataType.toLowerCase() + 'Name'])
        .join(',');
    }
  } else {
    identifiers = identifiers
      .map((m) => m[dataType.toLowerCase() + 'Name'])
      .join(',');
  }

  dataType = dataType === 'Voucher' ? 'Vouchers' : dataType;

  return {
    dataType,
    identifiers,
  };
};

export const { setDataType, setIdentifiers } = institutionSlice.actions;

export default institutionSlice.reducer;
