import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { logout } from '../../store/session/authSlice';

const Header = ({ showTabs = true }) => {
  const { me } = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState(
    location.pathname.toLocaleLowerCase().includes('agreement')
      ? '/Agreement'
      : '/Voucher'
  );

  const logoutUser = () => {
    dispatch(logout());
    document.location.reload();
  };

  useEffect(() => {
    if (
      me.role &&
      me.role.roleName.toLowerCase() === 'user' &&
      !me.voucherAccess &&
      !me.agreementAccess
    ) {
      navigate('/notAllowed');
      return;
    }

    if (
      me.role &&
      me.role.roleName.toLowerCase() === 'user' &&
      !me.voucherAccess &&
      location.pathname.toLowerCase().includes('voucher')
    ) {
      navigate('/Agreement');
      return;
    }

    if (
      me.role &&
      me.role.roleName.toLowerCase() === 'user' &&
      !me.agreementAccess &&
      location.pathname.toLowerCase().includes('agreement')
    ) {
      navigate('/Voucher');
      return;
    }

    setCurrentFilter(
      location.pathname.toLowerCase().includes('agreement')
        ? '/Agreement'
        : '/Voucher'
    );

    return () => {};
  }, [location, me]);

  return (
    <div className='fixed top-0 left-0 right-0 flex flex-col'>
      <div className='w-full bg-white h-16 flex items-center px-2 justify-between border-b'>
        <div className='flex items-center'>
          <img src='/logo.png' className='h-8 ml-2' />
          {me.voucherAccess && (
            <Link
              className={`font-semibold ml-8 ${
                window.location.pathname
                  .toLocaleLowerCase()
                  .includes('/voucher')
                  ? 'text-primary'
                  : ''
              }`}
              to='/Voucher'
            >
              Voucher
            </Link>
          )}
          {me.agreementAccess && (
            <Link
              className={`font-semibold ml-8 ${
                window.location.pathname
                  .toLocaleLowerCase()
                  .includes('/agreement')
                  ? 'text-primary'
                  : ''
              }`}
              to='/Agreement'
            >
              Agreement
            </Link>
          )}
          {me.role && me.role.roleName.toLowerCase().includes('admin') && (
            <Link
              className={`font-semibold ml-8 ${
                window.location.pathname
                  .toLocaleLowerCase()
                  .includes('/voucher')
                  ? 'text-primary'
                  : ''
              }`}
              to='/Voucher'
            >
              Voucher
            </Link>
          )}
          {me.role && me.role.roleName.toLowerCase().includes('admin') && (
            <Link
              className={`font-semibold ml-8 ${
                window.location.pathname
                  .toLocaleLowerCase()
                  .includes('/agreement')
                  ? 'text-primary'
                  : ''
              }`}
              to='/Agreement'
            >
              Agreement
            </Link>
          )}
        </div>
        <div className='flex items-center justify-end gap-4'>
          <div className='flex items-end flex-col justify-center'>
            <p className='opacity-50 font-semibold leading-none'>
              Welcome, {me.firstname}!
            </p>
            <div className='flex'>
              {me.role && me.role.roleName === 'Site Admin' && (
                <>
                  {window.location.pathname != '/users' ? (
                    <Link className='text-link font-semibold' to='/users'>
                      Admin
                    </Link>
                  ) : (
                    <Link className='text-link font-semibold' to='/'>
                      Home
                    </Link>
                  )}
                  <p className='font-bold mx-1 text-gray-400'>|</p>
                </>
              )}
              <button
                className='text-link font-semibold mr-1'
                onClick={logoutUser}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      {showTabs && (
        <div className='flex items-center justify-evenly gap-8 bg-gray-200 pt-2 px-2'>
          <Link
            to={currentFilter}
            className={`${
              window.location.pathname.toLocaleLowerCase() === '/voucher' ||
              window.location.pathname.toLocaleLowerCase() === '/agreement'
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Overview
          </Link>
          <Link
            to={`${currentFilter}/current`}
            className={`${
              window.location.pathname.includes('/current')
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Current Courses
          </Link>
          <Link
            to={`${currentFilter}/upcoming`}
            className={`${
              window.location.pathname.includes('/upcoming')
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Upcoming Courses
          </Link>
          <Link
            to={`${currentFilter}/past`}
            className={`${
              window.location.pathname.includes('/past')
                ? 'text-primary bg-gray-50'
                : ''
            } font-bold flex-1 py-2 rounded-t-md`}
          >
            Past Course Results
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
