import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table/Table';
import {
  getEnrolledInCurrent,
  getEnrolledInPast,
  getEnrolledInUpcoming,
  getFunnelValues,
  getInstitutionDetails,
  getInstitutionNames,
  getInstitutions,
  setDataType,
  setIdentifiers,
  setInstitution,
} from '../../store/session/institutionSlice';
import Select from 'react-select';
import FunnelChart from '../../components/FunnelChart/FunnelChart';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.auth);
  const {
    currentEnrolledstatus,
    currentEnrolled,
    upcomingEnrolledstatus,
    upcomingEnrolled,
    institutionDetailsStatus,
    institutionDetails,
    pastEnrolled,
    pastEnrolledStatus,
    status,
    funnelValues,
    funnelStatus,
    dataType,
    identifiers,
    selectedInstitution,
    institutionNames,
    institutions,
    agreements,
  } = useSelector((state) => state.institution);

  useEffect(() => {
    if (!dataType) return;

    if (currentEnrolledstatus === 'idle') {
      dispatch(getEnrolledInCurrent());
    }
    if (upcomingEnrolledstatus === 'idle') {
      dispatch(getEnrolledInUpcoming());
    }
    if (pastEnrolledStatus === 'idle') {
      dispatch(getEnrolledInPast());
    }
    if (funnelStatus === 'idle') {
      dispatch(getFunnelValues());
    }
    if (dataType.toLowerCase() === 'agreement') {
      dispatch(getInstitutionNames());
    }
    if (institutionDetailsStatus === 'idle') {
      dispatch(getInstitutionDetails());
    }

    return () => {};
  }, [dataType, identifiers, agreements]);

  useEffect(() => {
    if (!location.pathname.includes(dataType)) {
      dispatch(
        setDataType(
          location.pathname.includes('Agreement') ? 'Agreement' : 'Voucher'
        )
      );
      dispatch(setIdentifiers(['all']));
    }

    if (me.role && me.role.roleName.toLowerCase() !== 'user') {
      if (status === 'idle') {
        dispatch(getInstitutions());
      }
    } else {
      if (status === 'idle') {
        dispatch(getInstitutions(me.agreements?.map((a) => a.agreementName)));
      }
    }

    return () => {};
  }, [me, location]);

  useEffect(() => {
    if (institutions.length > 0) {
      dispatch(setInstitution(selectedInstitution));
    }

    return () => {};
  }, [institutions, dataType]);

  return (
    <div className='container mx-auto mt-36 flex flex-col justify-center items-center'>
      <div className='pb-8 w-fit flex justify-center items-center'>
        {dataType?.toLowerCase() == 'voucher' ? (
          <h1 className='text-3xl font-bold'>
            {dataType?.toLowerCase() === 'agreement' ? (
              <>{institutionNames}</>
            ) : identifiers[0] == 'all' ? (
              <>{dataType}s: All</>
            ) : (
              <>
                {dataType}s:{' '}
                {identifiers
                  .map((i) => i[dataType.toLowerCase() + 'Name'])
                  .join(',')}
              </>
            )}
          </h1>
        ) : (
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '1.5rem',
                fontWeight: '700',
              }),
              container: (styles) => ({
                ...styles,
                minWidth: '300px',
              }),
            }}
            value={{
              label: selectedInstitution?.institution,
              value: selectedInstitution?.id,
            }}
            options={institutions.map((i) => {
              return {
                label: i.institution,
                value: i.id,
              };
            })}
            onChange={(val) => {
              dispatch(
                setInstitution({ id: val.value, institution: val.label })
              );
            }}
          />
        )}
      </div>
      <div className='flex justify-between w-full'>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Total Students</p>
          <p>{institutionDetails.total ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Enrolled</p>
          <p>{institutionDetails.enrolled ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Active</p>
          <p>{institutionDetails.active ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>Students Graded</p>
          <p>{institutionDetails.graded ?? 'N/A'}</p>
        </div>
        <div className='border p-4 min-w-[200px] rounded-md'>
          <p className='font-bold'>{dataType}s</p>
          {agreements.length > 0 && dataType.toLowerCase() == 'agreement' ? (
            <Select
              isMulti
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  fontWeight: '700',
                }),
                container: (styles) => ({
                  ...styles,
                }),
              }}
              placeholder='All'
              options={agreements?.map((i) => {
                return {
                  label: i.agreementName,
                  value: i.id,
                };
              })}
              value={
                identifiers[0] == 'all'
                  ? null
                  : identifiers.map((i) => {
                      return {
                        value: i.id,
                        label: i.agreementName,
                      };
                    })
              }
              onChange={(val) => {
                dispatch(
                  setIdentifiers(
                    val.length === 0
                      ? ['all']
                      : val.map((v) => {
                          return {
                            id: v.value,
                            agreementName: v.label,
                          };
                        })
                  )
                );
              }}
            />
          ) : (
            <Select
              isMulti
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: 'transparent',
                  fontWeight: '700',
                }),
                container: (styles) => ({
                  ...styles,
                }),
              }}
              placeholder='All'
              options={me[dataType?.toLowerCase() + 's']?.map((i) => {
                return {
                  label: i[dataType?.toLowerCase() + 'Name'],
                  value: i.id,
                };
              })}
              value={
                identifiers[0] == 'all'
                  ? null
                  : identifiers.map((i) => {
                      return {
                        value: i.id,
                        label: i[dataType.toLowerCase() + 'Name'],
                      };
                    })
              }
              onChange={(val) => {
                dispatch(
                  setIdentifiers(
                    val.length === 0
                      ? ['all']
                      : val.map((v) => {
                          return {
                            id: v.value,
                            [dataType.toLowerCase() + 'Name']: v.label,
                          };
                        })
                  )
                );
              }}
            />
          )}
        </div>
      </div>
      <div className='grid grid-flow-col w-full pt-12 gap-8'>
        <div className='flex col-span-2 flex-col text-left gap-8'>
          <div>
            <Table
              title='Students Enrolled for Current Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
                {
                  key: 'Proj to pass',
                  label: 'Students Proj to Pass',
                },
                {
                  key: 'Marginally Behind',
                  label: 'Students Marginally Behind',
                },
                {
                  key: 'Proj to fail',
                  label: 'Students Proj to Fail',
                },
              ]}
              showPagination={false}
              entries={currentEnrolled}
              showActions={false}
            />
          </div>
          <div>
            <Table
              title='Students Enrolled for Upcoming Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
              ]}
              showPagination={false}
              entries={upcomingEnrolled}
              showActions={false}
            />
          </div>
          <div>
            <Table
              title='Students Completing Past Courses'
              headers={[
                { key: 'Course Code', label: 'Course Code' },
                { key: 'Course Start Date', label: 'Course Start Date' },
                { key: 'Course End Date', label: 'Course End Date' },
                { key: 'Enrolled Students', label: 'Students Enrolled' },
                { key: 'Passed', label: 'Students Passed' },
                { key: 'Failed', label: 'Students Failed' },
              ]}
              showPagination={false}
              entries={pastEnrolled}
              showActions={false}
            />
          </div>
        </div>
        <div className='border p-4 col-span-1 bg-white rounded-md flex flex-col gap-4 h-fit'>
          <p>Current Activity</p>
          <FunnelChart
            values={{
              total: funnelValues ? funnelValues['Total Students'] : 0,
              enrolled: funnelValues ? funnelValues['Enrolled Students'] : 0,
              active: funnelValues ? funnelValues['Active Students'] : 0,
              pass: funnelValues ? funnelValues['Proj to pass'] : 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
