import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { login } from '../../store/session/authSlice';

const Login = () => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || '/';

  const { me, status, error } = useSelector((state) => state.auth);

  const loginUser = () => {
    dispatch(login(loginData));
  };

  useEffect(() => {
    if (status === 'success') {
      navigate(from == '/login' || !from ? '/' : from);
    }

    return () => {};
  }, [status]);

  if (status === 'loading-authentication') {
    return <p className='opacity-50 font-bold'>Loading...</p>;
  }

  return (
    <div className='w-full h-screen flex justify-center items-center flex-col'>
      <img src='/logo.png' className='w-40 mb-10' />
      <div className='flex flex-col gap-4 w-full sm:w-[500px] p-10 tile'>
        <p className='font-bold text-xl'>
          Welcome to the DFI Enterprise Portal
        </p>
        <p className='font-semibold opacity-50 text-xl leading-3 mb-8'>
          Sign in to your account
        </p>
        <Input
          placeholder='Email'
          onChange={(e) =>
            setLoginData({ ...loginData, email: e.target.value })
          }
        />
        <Input
          placeholder='Password'
          onChange={(e) =>
            setLoginData({ ...loginData, password: e.target.value })
          }
          type='password'
        />
        {error ? <p className='font-bold text-red-400'>{error}</p> : <br />}
        <Button onClick={loginUser}>Login</Button>
        <Link className='text-link' to='/forgotpassword'>
          Forgot your password?
        </Link>
      </div>
    </div>
  );
};

export default Login;
