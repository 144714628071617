import { configureStore } from '@reduxjs/toolkit';
import authReducer from './session/authSlice';
import institutionSlice from './session/institutionSlice';
import usersReducer from './session/usersSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    institution: institutionSlice,
  },
});
