import axios from 'axios';

const api = axios.create();

export const loginUrl = '/api/v1/auth/login';
export const forgotUrl = '/api/v1/auth/forgotpassword';
export const resetUrl = '/api/v1/auth/resetpassword';
export const authenticateUrl = '/api/v1/auth/authenticate';

export const usersUrl = '/api/v1/users';
export const rolesUrl = '/api/v1/roles';
export const agreementsUrl = '/api/v1/agreements';
export const institutionNamesUrl = '/api/v1/agreements/institutions';
export const vouchersUrl = '/api/v1/vouchers';

export const currentCoursesUrl = '/api/v1/courses/current';
export const upcomingCoursesUrl = '/api/v1/courses/upcoming';
export const currentEnrolledCoursesUrl = '/api/v1/courses/current/enrolled';
export const upcomingEnrolledCoursesUrl = '/api/v1/courses/upcoming/enrolled';
export const pastEnrolledCoursesUrl = '/api/v1/courses/past/enrolled';
export const pastCoursesUrl = '/api/v1/courses/past';
export const institutionsUrl = '/api/v1/institutions';
export const institutionDetailsUrl = '/api/v1/institutions/details';
export const funnelUrl = '/api/v1/institutions/funnel';

export default api;
